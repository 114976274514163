<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items"/>
    <div class="mb-4 w-100 pedisteps-view mt-4 mt-sm-0">

      <div class="row">
        <div class="col-lg-3">
          <div class="p-3 border-bottom clickable" :class="{ active: isSelf }">
            <div @click="selectUser" class="media pointer">
              <div class="align-self-center mr-3">
                <img :src="$store.state.user.image"
                     class="avatar-xs rounded-circle"
                     alt="My Profile Image"/>
              </div>
              <div class="media-body">
                <h5 class="font-size-13 mt-0 mb-1">
                  {{ $store.state.user.name }}</h5>
                <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                  Active
                </p>
              </div>
            </div>
          </div>
          <div class="chat-leftsidebar-nav">
            <h5 class="font-size-14 px-3 my-3">{{ $t('pages.pedisteps.patients') }}</h5>
            <simplebar id="scrollPatients">
              <ul class="list-unstyled patient-list">
                <li v-for="(patient, k) of $store.state.monitor.patients"
                    :key="`patient-${k}`"
                    @click="selectPatient(patient)"
                    :class="{ active: selectedPatient.id === patient.id }"
                >
                  <a href="javascript: void(0);">
                    <div class="media">
                      <div
                          class="user-img align-self-center mr-3 away"
                          v-if="patient.image"
                          :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                        <img
                            :src="`${patient.image}`"
                            class="rounded-circle avatar-xs"
                            alt/>
                        <span class="user-status"></span>
                      </div>
                      <div class="user-img mr-3 away" v-if="!patient.image"
                           :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                        <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                        </div>
                        <span class="user-status"></span>
                      </div>
                      <div class="media-body overflow-hidden">
                        <h5 class="text-truncate font-size-14 mb-1">
                          {{ patient.name }}
                        </h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row my-3">
            <div class="col-lg-8 col-12">
              <h3 class="text-lg-left text-center mb-3">{{ modeTitle }}</h3>
            </div>
            <div class="col-lg-4 col-12 text-right">
              <ul class="list-inline pedisteps-view-nav text-right mb-0">
                <li class="list-inline-item d-none d-sm-inline-block w-100">
                  <b-dropdown class="w-100" toggle-class="w-100 p-0" right variant="white">
                    <template v-slot:button-content>
                      <div class="d-flex flex-column text-right w-100">
                        <h5 class="font-size-13 mb-1 text-truncate">{{ selectedTimeOption }}</h5>
                        <p class="font-size-13 text-muted text-truncate mb-0">
                          <i class="mdi mdi-timetable"></i>
                          {{ $t('pages.pedisteps.timerange') }}
                        </p>
                      </div>
                    </template>
                    <b-dropdown-item
                        v-for="(option,k) in timeOptions"
                        :key="`timeopt-${k}`"
                        @click="selectTimeRange(option)">
                      {{ option.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="pedisteps-heatmap" v-if="mode<2">
            <div class="steps-container">
              <div class="steps-left">
                <i :key="`sl-${i}`"
                   :style="{left: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 30px 5px ${heatRgba(valL[i])}`, background: heatRgba(valL[i]) }"
                   v-for="(s,i) in pedisol40"></i>
              </div>
              <div class="steps-center"></div>
              <div class="steps-right">
                <i :key="`sl-${i}`"
                   :style="{right: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 30px 5px ${heatRgba(valR[i])}`, background: heatRgba(valR[i]) }"
                   v-for="(s,i) in pedisol40"></i>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>Analysis coming soon...</p>
            </div>
          </div>
          <div class="pedisteps-events custom-scrollbar" v-if="mode === 2">
            <ul v-if="mappedEvents.length" class="list-group">
              <li v-for="(event,k) in mappedEvents" :key="`ev-${k}`"
                  class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  <span class="badge mr-2" :class="event.labelClass">{{ event.labelText }}</span>
                  <span class="event-description" v-html="event.description"></span>
                </span>
                <span class="event-time">{{ event.timestamp }}</span>
              </li>
            </ul>
            <p v-else class="text-lg-left text-center text-muted">No events found for this user.</p>
          </div>
          <div class="pedisteps-insights" v-if="mode === 3">
            <p>No insights available at the moment.</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pedisteps-tabs">
            <b-tabs content-class="py-3 border-left" tab-class="p-1" justified v-model="mode">
              <b-tab title="Tab Realtime" title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-wifi-line font-size-20"></i>
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.realtime') }}</span>
                </template>
                <b-card-text>
                </b-card-text>
              </b-tab>
              <b-tab title="Tab Gait" active title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-walk-line font-size-20"></i>
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.gait') }}</span>
                </template>
                <b-card-body>
                  <h5 class="font-size-14 mb-3">{{ $t('pages.pedisteps.sessions') }}</h5>
                  <simplebar id="scrollSessions">
                    <ul class="list-unstyled session-list">
                      <li v-for="(s, k) of sessions" :key="`session-${k}`"
                          :class="{'active': !!selectedSession && selectedSession.Session===s.Session}">
                        <a href="javascript: void(0);" @click="selectedSession = s;"
                           :title="`Session: ${s.Session}, Timestamp: ${s.T}`">
                          <div class="media">
                            {{ formatTimestamp(s.T) }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </b-card-body>
              </b-tab>
              <b-tab title="Tab Events" title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-list-unordered font-size-20"></i> {{ events.length }}
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.eventlog') }}</span>
                </template>
                <b-card-text>
                </b-card-text>
              </b-tab>
              <b-tab title="Tab Insights" title-link-class="p-1">
                <template v-slot:title>
                  <i :class="['font-size-20', {'ri-lightbulb-line': !lightbulbActive, 'ri-lightbulb-fill': lightbulbActive}]"></i>
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.insights') }}</span>
                </template>
                <b-card-text>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import simplebar from 'simplebar-vue'
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
// eslint-disable-next-line no-unused-vars
import {collection, getFirestore, onSnapshot, query, where, orderBy, limit} from 'firebase/firestore'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import {pedisol30Data, pedisol40Data} from './data'
import Background from '@/assets/images/shoe-prints.svg'
import {monitorMethods, userMethods} from '@/state/helpers'
import {getApi} from '@/api'

const firebaseConfig = {
  apiKey: 'AIzaSyDY20ZKArG6BDHunz_Hr9HSbIOikxemZ34',
  authDomain: 'vrsteps-3866d.firebaseapp.com',
  databaseURL: 'https://vrsteps-3866d.firebaseio.com',
  projectId: 'vrsteps-3866d',
  storageBucket: 'vrsteps-3866d.appspot.com',
  messagingSenderId: '551929963690',
  appId: '1:551929963690:web:7190bead804cb7a4505cd3',
  measurementId: 'G-KZE3TMDG9R'
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)
/**
 * Pedisteps Component
 */
export default {
  page: {
    title: 'Pedisteps Gait',
    meta: [{name: 'description'}],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
  },
  data() {
    return {
      events: [],
      insights: [],
      items: [
        {
          text: 'VRsteps',
          href: '/',
        },
        {
          text: 'Pedisteps',
          active: true,
        },
      ],
      limits: {
        sessionData: 5000,
        events: 1000,
        sessions: 100,
      },
      sessions: [],
      mode: 0,
      modes: {REALTIME: 0, GAIT: 1, EVENTS: 2, INSIGHTS: 3},
      pedisol30: pedisol30Data,
      pedisol40: pedisol40Data,
      pedisolSize: 40,
      realtimeBackground: Background,
      L: [],
      R: [],
      rtData: [],
      submitted: false,
      title: 'pages.pedisteps.title',
      timeOptions: [
        {label: 'Last 24h', value: this.calculateTimestamp(24)},
        {label: 'Last 3 days', value: this.calculateTimestamp(24 * 3)},
        {label: 'Last 7 days', value: this.calculateTimestamp(24 * 7)},
        {label: 'Last 30 days', value: this.calculateTimestamp(24 * 30)},
        {label: 'Last 90 days', value: this.calculateTimestamp(24 * 30)},
        {label: 'Last 180 days', value: this.calculateTimestamp(24 * 30)}
      ],
      selectedPatient: {
        id: null,
        name: '',
        image: '',
        activation_token: '',
        lastSession: null,
        lastSessionDate: 'N/A',
        isActive: false,
        totalUsageTime: 'N/A',
        totalEvents: '0'
      },
      selectedTimeOption: '',
      selectedTimestamp: null,
      selectedSession: null,
      lightbulbActive: false
    }

  },
  methods: {
    ...userMethods,
    ...monitorMethods,
    ...getApi(),

    selectUser() {
      this.selectedPatient = this.$store.state.user;
      this.loadEvents();
      this.loadSessions();
      console.log("selectUser", this.selectedPatient)
    },

    selectPatient(patient) {
      this.selectedPatient = patient
      this.loadEvents();
      this.loadSessions();
      console.log("selectPatient", this.selectedPatient)
    },

    // Handle option selection
    selectTimeRange(option) {
      this.selectedTimeOption = option.label;
      this.selectedTimestamp = option.value; // Store the selected timestamp
      console.log('Selected timestamp:', this.selectedTimestamp);
      this.loadEvents();
    },

    // Calculate the timestamp for X hours ago
    calculateTimestamp(hoursAgo) {
      const now = new Date();
      const timestamp = new Date(now.getTime() - hoursAgo * 60 * 60 * 1000);
      return Math.floor(timestamp.getTime()); // Firebase expects timestamps in milliseconds
    },

    heatRgba(pressure) {
      if (pressure < 0 || pressure > 255) return 'rgba(255, 255, 255, 0.9)'
      if (pressure === 0) return 'rgba(255, 255, 255, 0.25)' // White

      const blueRangeEnd = 100
      const greenRangeEnd = 150
      const yellowRangeEnd = 200
      let r, g, b

      if (pressure <= blueRangeEnd) {
        // Transition from white to blue
        r = g = Math.max(255 - pressure * 2.55, 0)
        b = 255
      } else if (pressure <= greenRangeEnd) {
        // Transition from blue to green
        const factor = (pressure - blueRangeEnd) / (greenRangeEnd - blueRangeEnd)
        r = 0
        g = 255
        b = 255 - factor * 255
      } else if (pressure <= yellowRangeEnd) {
        // Transition from green to yellow
        const factor = (pressure - greenRangeEnd) / (yellowRangeEnd - greenRangeEnd)
        r = factor * 255
        g = 255
        b = 0
      } else {
        // Transition from yellow to red
        const factor = (pressure - yellowRangeEnd) / (255 - yellowRangeEnd)
        r = 255
        g = 255 - factor * 255
        b = 0
      }

      return `rgba(${r}, ${g}, ${b}, 0.25)`
    },

    setLastSession() {
      console.log("setLastSession", this.selectedTimestamp, this.$store.state.user.activation_token);

      //do it for self
      const q = query(
          collection(db, 'sessions'),
          orderBy('T', 'desc'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.$store.state.user.activation_token),
          limit(1)
      );

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const latestDoc = querySnapshot.docs[0].data();
          this.setUserLastSession({lastSession: latestDoc});
        } else {
          this.setUserLastSession({lastSession: null});
        }
      }, (error) => {
        console.error(`Error fetching data for patient ${this.$store.state.user.name}: `, error);
      });

      //do it for patients
      this.$store.state.monitor.patients.forEach(patient => {
        const token = patient.activation_token;
        const q = query(
            collection(db, 'sessions'),
            orderBy('T', 'desc'),
            where('T', '>=', this.selectedTimestamp),
            where('Token', '==', token),
            limit(1)
        );

        this.unsubscribe = onSnapshot(q, (querySnapshot) => {
          if (!querySnapshot.empty) {
            const latestDoc = querySnapshot.docs[0].data();
            this.setPatientLastSession({patientId: patient.id, lastSession: latestDoc});
          } else {
            this.setPatientLastSession({patientId: patient.id, lastSession: null});
          }
        }, (error) => {
          console.error(`Error fetching data for patient ${patient.name}: `, error);
        });
      });
    },

    loadSessions() {
      console.log("loadSessions");

      this.sessions.splice(0);
      //do it for self
      const q = query(
          collection(db, 'sessions'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.selectedPatient.activation_token),
          orderBy('T', 'desc'),
          limit(this.limits.sessions)
      );

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const s = change.doc.data();
          const changeType = change.type;
          if (changeType === 'added') {
            this.sessions.push(s);
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });

    },

    loadRealtime() {
      if (this.selectedPatient.lastSession === null) return;
      console.log("loadRealtime", this.selectedPatient.lastSession.Session);

      // Set query to fetch the latest value from the Firestore
      const q = query(
          collection(db, 'pedisol'),
          orderBy('T', 'desc'), // Order by timestamp descending
          where('Session', '==', this.selectedPatient.lastSession.Session),
          limit(1) // Limit the result to the latest document
      );

      // Real-time listener for new additions or updates in Firestore
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data();
          const changeType = change.type;

          if (changeType === 'added' || changeType === 'modified') {
            // Set the latest left and right foot data
            this.L = docData.L;
            this.R = docData.R;
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });
    },

    loadGait() {

      //reset
      this.L.splice(0);
      this.R.splice(0);

      const q = query(collection(db, 'pedisol'),
          orderBy('T', 'desc'), // Make sure documents are ordered by createdAt
          where('T', '>=', this.selectedTimestamp),
          where('Session', '==', this.selectedSession.Session),
          limit(this.limits.sessionData)
      )

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data()
          const changeType = change.type

          if (changeType === 'added') {
            this.L = docData.L
            this.R = docData.R
          }
        })

      }, (error) => {
        console.error('Error listening to collection changes: ', error)
      })
    },

    loadEvents() {

      this.events.splice(0);
      console.log("loadEvents", this.selectedPatient.activation_token, this.selectedTimestamp)

      const q = query(
          collection(db, 'events'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.selectedPatient.activation_token),
          orderBy('T', 'desc'),
          limit(this.limits.events)
      );

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const e = change.doc.data();
          const changeType = change.type;

          if (changeType === 'added') {
            this.events.push(e);
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });
    },

    loadInsights() {

    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const currentYear = new Date().getFullYear();
      const eventYear = date.getFullYear();

      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('en-US', {month: 'short'}); // "Jun"
      const time = date.toTimeString().split(' ')[0]; // "HH:MM:SS"

      // If it's the current year, don't display the year
      if (eventYear === currentYear) {
        return `${day} ${month}, ${time}`;
      } else {
        return `${day} ${month} ${eventYear}, ${time}`;
      }
    },

    formatTechnicalEvent(event) {
      const leftColor = event.L ? 'text-success' : 'text-danger'; // Green for true, red for false
      const rightColor = event.R ? 'text-success' : 'text-danger';
      return 'Insoles state: ' + `<span class="${leftColor}">●</span> ` + this.$t('general.left') + `, <span class="${rightColor}">●</span> ` + this.$t('general.right');
    },

    formatWeightAlarm(event) {
      const left = Math.round((event.SumL - event.UserExpectedSumL) * 100 / event.UserExpectedSumL);
      const right = Math.round((event.SumR - event.UserExpectedSumR) * 100 / event.UserExpectedSumR);
      return `Weight Bearing: Left ${left}% / Right ${right}%`;
    },

    toggleInsightsIcon() {
      this.lightbulbActive = !this.lightbulbActive
      if (this.lightbulbActive)
        setTimeout(this.toggleInsightsIcon, 200);
      else setTimeout(this.toggleInsightsIcon, 3000);

    }
  },
  watch: {
    mode(newMode) {

      if (this.unsubscribe) {
        this.unsubscribe()
      }

      switch (newMode) {
        case 0:
          this.loadRealtime();
          break;
        case 1:
          // this.loadSessions();
          break;
        case 2:
          // moved to select patient
          break;
        case 3:
          this.loadInsights();
          break;
      }
    },
    selectedSession() {
      this.loadGait();
    }
  },
  computed: {
    mappedEvents() {
      return this.events.map(e => {
        let labelClass = '';
        let labelText = '';
        let description = '';
        let timestamp = this.formatTimestamp(e.T);

        switch (e.Type) {
          case "Error":
            labelClass = 'badge-warning'; // Yellow for technical events
            labelText = 'Technical';
            description = this.formatTechnicalEvent(e.Event);
            break;
          case "Weight":
            labelClass = e.Event === 'Alarm' ? 'badge-danger' : 'badge-warning'; // Red for medical events
            labelText = 'Medical';
            description = this.formatWeightAlarm(e);
            break;
          default:
            labelClass = 'badge-secondary'; // Grey for unknown events
            labelText = 'Unknown Event';
            description = 'No description available.';
            break;
        }

        return {
          labelClass,
          labelText,
          timestamp,
          description
        };
      });
    },

    messages() {
      return this.$store.state.monitor.messages.filter(m => m.from_id === this.selectedPatient.id || m.to_id === this.selectedPatient.id).sort((a, b) => {
        return a.send_date < b.send_date ? -1 : 1
      })
    },

    sensors() {
      return this.pedisolSize === 30 ? [] : []
    },

    valL() {
      if (this.L.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.L
    },
    valR() {
      if (this.R.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.R
    },

    isSelf() {
      return this.selectedPatient.id === this.$store.state.user.id;
    },

    modeTitle() {
      switch (this.mode) {
        case 0:
          return "Realtime Patient Gait";
        case 1:
          return "Patient Gait";
        case 2:
          return "Patient Events";
        case 3:
          return "Patient Insights";
        default:
          return "";
      }
    }
  },
  mounted() {

    const userId = this.$store.state.user.id
    const accessToken = this.$store.state.user.access_token

    const firstOption = this.timeOptions[2];
    this.selectedTimeOption = firstOption.label;
    this.selectedTimestamp = firstOption.value;

    setTimeout(this.toggleInsightsIcon, 3000)

    this.selectUser();

    this.getPatients(userId, accessToken).then(response => response.json()).then(
        (results) => {
          this.setPatients({patients: results.patients});
          this.setLastSession();
        }
    )
  },
  beforeDestroy() {
    // Stop listening to changes
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>
